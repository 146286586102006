import React, {Component} from "react";
//import $ from 'jquery'
import Nav from "./components/Navigation";
import Portfolio from "./components/Portfolio";

class App extends Component{
    constructor(){
        super();
        this.state={
            navData:{},
            portfolioData:{},
        }
    }


    getNavData(){
        var data = require("./assets/json/navigation.json")
        this.setState({navData: data})
    }
    getPortfolio(){
        var data = require("./assets/json/portfolio.json");
        this.setState({portfolioData:data})
    }

    componentDidMount(){
        this.getNavData()
        this.getPortfolio()
    }


    render(){
        return (
            <div>
                <Nav data={this.state.navData}/>
                <Portfolio data={this.state.portfolioData.portfolio} />
            </div>
        )
    }

}

export default App






    
        // fetch("./assets/json/navigation.json")
        // .then(res => res.text())
        // .then(data => 
        //     console.log(JSON.parse(data))
        //     )
        //const nav = () => JSON.parse(JSON.stringify(NavData));
        // const nav = [...NavData]
        //console.log(JSON.parse(nav))
        // fetch("./assets/json/navigation.json")
        // .then((res)=>res.text())
        // .then((data)=>{
        //    // const {nav} = response.data
        //     console.log(JSON.parse(data))
        //     //this.setState({navData: nav})
        // })