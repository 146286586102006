import React from "react";

function Navigation(props){
    

    var navList = ""
    if(props.data && props.data.navigation && props.data.navigation.length > 0){
        const navData = props.data.navigation;

        var filtered = navData.filter(item => item.isAvailable !== false)            
        navList = filtered.map((item, i) => {
            const target = item.target ? "_blank" : ""
            return(
                <li key={i}><a className="smoothscroll" href={item.url} target={target}>{item.title}</a></li>
            )
        })
        
    }

    return (    
        <header id="home">
            <nav id="nav-wrap">
                <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                <ul id="nav" className="nav">
                    {navList}
                    {/* <li className="current"><a className="smoothscroll" href="#home">Home</a></li> */}
                    
                </ul>
            </nav>
        </header>
    )
    
}
export default Navigation;




