import React, {Component} from "react"

class Portfolio extends Component{

  clickHandler=(e)=>{
    const url = e.currentTarget.value
    if(url){
      window.open(url, "_blank")
    }
  }

    render(){
        
        if(this.props.data && this.props.data.length > 0){
            
            var filtered = this.props.data.filter(site => site.isAvailable !== false)            

            var portfolioList = filtered.map((site, i)=>{
              const subtitle = (site.subtitle) ? site.subtitle : "";
              const categoryIcon = site.category + "-type-icon";
              const hasLink = site.url ? "link-icon" : "link-icon hide";
              const url = site.url ? site.url : ""

                return(
                    <div className="columns portfolio-item" key={i}>
                        <div className="item-wrap">
                            <button onClick={this.clickHandler} value={url} title={site.title} >
                                <img src={site.image} alt={site.title} className="ui fluid image"/>
                                <div className="overlay">
                                    <div className="portfolio-item-meta">
                                    <h5>{site.title}</h5>
                                    <h6>{subtitle}</h6>
                                        <p>{site.category}</p>
                                    </div>
                                </div>
                                
                                <div className="overlay-footer">
                                  <div className={categoryIcon}></div>
                                  <div className={hasLink}><i className="fa fa-link"></i></div>
                              </div>
                            </button>
                        </div>
                    </div>
                    
                )
            })
        }

        return (
          <section id="work">
            <div className="row">
              <div className="twelve columns collapsed">
                <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf" >
                  {portfolioList}
                </div>
              </div>
            </div>
          </section>
        );
    }
}
export default Portfolio


